<template>
    <r-e-dialog :title="type === 'add' ? '添加房源' : '编辑房源'" show-footer :visible.sync="dialogVisible" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="小区" prop="communityUuid">
                    <el-select v-model="formPublish.communityUuid" placeholder="请选择小区" style="width: 200px;"
                               @change="communityChange">
                        <el-option v-for="(item,index) in commonityList" :label="item.name" :value="item.uuid"
                                   :key="index"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="楼栋" prop="buildUuid">
                    <el-select v-model="formPublish.buildUuid" placeholder="请选择楼栋" style="width: 200px;"
                               @change="buildChange">
                        <el-option v-for="(item,index) in buildList" :label="item.name" :value="item.uuid"
                                   :key="index"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="单元" prop="">
                    <el-select v-model="formPublish.unitUuid" placeholder="请选择单元" style="width: 200px;">
                        <el-option v-for="(item,index) in unitList" :label="item.name" :value="item.uuid" :key="index"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="楼层" prop="floorNum">
                    <el-input v-model="formPublish.floorNum" placeholder="请输入楼层"/>
                </el-form-item>
                <el-form-item label="房号" prop="roomNum">
                    <el-input v-model="formPublish.roomNum" placeholder="请输入房号"/>
                </el-form-item>
                <el-form-item label="房屋面积" prop="area">
                    <el-input v-model="formPublish.area" placeholder="请输入房屋面积" style="width: 200px;">
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="选择房间" prop="" style="width: 100%;">
                    <div class="flex" style="margin-bottom: 10px">
                        <div style="width: 60px;text-align-last: justify;margin-right: 15px">房间1</div>
                        <el-input v-model="formPublish.area1In" placeholder="套内面积"
                                  style="width: 200px;margin-right: 10px;">
                            <template slot="append">㎡</template>
                        </el-input>
                    </div>
                    <div class="flex" style="margin-bottom: 10px">
                        <div style="width: 60px;text-align-last: justify;margin-right: 15px">房间2</div>
                        <el-input v-model="formPublish.area2In" placeholder="套内面积"
                                  style="width: 200px;margin-right: 10px;">
                            <template slot="append">㎡</template>
                        </el-input>
                    </div>
                    <div class="flex">
                        <div style="width: 60px;text-align-last: justify;margin-right: 15px">房间3</div>
                        <el-input v-model="formPublish.area3In" placeholder="套内面积"
                                  style="width: 200px;margin-right: 10px;">
                            <template slot="append">㎡</template>
                        </el-input>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="备注" style="width: 100%;">
                    <el-input type="textarea" :rows="3" v-model="formPublish.common" style="width: 100%;"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {apartmentSave, apartmentUpdate, getApartmentInfo} from '@/api/guaranteed-rental-housing-resources';
import {getCommonityListApi, getDuildUnitListApi} from '@/api/community-management';
import {MessageSuccess} from "@custom/message";

export default {
    name: "layer-add-housing",
    data() {
        return {
            dialogVisible: false,
            type: "",
            formPublish: {
                communityUuid: null,
                buildUuid: null,
                unitUuid: null,
                floorNum: null,
                roomNum: null,
                area: null,
                common: null,
                area1In: null,
                area2In: null,
                area3In: null,
            },
            rules: {
                communityUuid: [{required: true, message: '请选择小区', trigger: 'change'}],
                buildUuid: [{required: true, message: '请选择楼栋', trigger: 'change'}],
                unitUuid: [{required: true, message: '请选择单元', trigger: 'change'}],
                floorNum: [{required: true, message: '请输入楼层', trigger: 'blur'}],
                roomNum: [{required: true, message: '请输入房号', trigger: 'blur'}],
                area: [{required: true, message: '请输入房屋面积', trigger: 'blur'}],
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            commonityList: [],
            buildList: [],
            unitList: [],
        };
    },
    props: {},
    components: {},
    mounted() {
        this.getCommonityList();
    },
    methods: {
        openDialog(type, data) {
            const that = this;
            this.type = type;

            if (type === 'edit') {
                const oldData = this.formPublish;
                const {uuid} = data;
                this.formPublish = {...oldData, ...data};
                getApartmentInfo({uuid}).then(({info})=>{
                    that.formPublish = {...oldData, ...info};
                    this.communityChange(info.communityUuid);
                    this.buildChange(info.buildUuid);
                    this.dialogVisible = true;
                });
            } else this.dialogVisible = true;
        },

        getCommonityList() {
            const that = this;
            getCommonityListApi().then(({list}) => {
                that.commonityList = list;
            });
        },

        communityChange(value) {
            const that = this;
            getDuildUnitListApi({uuid: value, type: 3}).then(({list}) => {
                that.buildList = list;
            });
        },

        buildChange(value) {
            const that = this;
            getDuildUnitListApi({uuid: value, type: 2}).then(({list}) => {
                that.unitList = list;
            });
        },

        clickSubmit() {
            let that = this;
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const {type} = this;
                    const {communityUuid, buildUuid, unitUuid, floorNum, roomNum, area, common, area1In, area2In, area3In, uuid} = this.formPublish;
                    const data = {communityUuid, buildUuid, unitUuid, floorNum, roomNum, area, common, area1In, area2In, area3In};

                    if (type === 'edit') data.uuid = uuid;

                    if (type === 'add') that.apartmentSave(data);
                    else that.apartmentUpdate(data)
                }
            });
        },

        clickCancel(closeType = "cancel") {
            this.formPublish = {
                communityUuid: null,
                buildUuid: null,
                unitUuid: null,
                floorNum: null,
                roomNum: null,
                area: null,
                common: null,
                area1In: null,
                area2In: null,
                area3In: null,
            };
            if (closeType === "update") this.$emit('handleChange');
            this.dialogVisible = false;

        },

        apartmentSave(data) {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            apartmentSave(data).then(res => {
                MessageSuccess("添加成功");
                that.clickCancel("update");
            }).finally(() => loading.close());
        },

        apartmentUpdate(data) {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            apartmentUpdate(data).then(res => {
                MessageSuccess("修改成功");
                that.clickCancel("update");
            }).finally(() => loading.close());
        },
    },
}
</script>

<style scoped>

</style>